import React from "react"
import "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Anchor from "@components/Anchor"
import Container from "@components/container"
import StaticHero from "@components/staticHero"
import { sectionMd } from "@styles/ui"

const SiteMap = ({ location }) => {
  const sitemapLinks = [
    {
      url: "/narcolepsy/",
      text: "Homepage",
    },
    {
      text: "Living With Narcolepsy",
      subLinks: [
        {
          url: "/narcolepsy/about-narcolepsy/",
          text: "About Narcolepsy",
        },
        {
          url: "/narcolepsy/living-with-narcolepsy/",
          text: "Living With Narcolepsy",
        },
      ],
    },
    {
      text: "Why XYWAV",
      subLinks: [
        {
          url: "/narcolepsy/what-is-xywav/",
          text: "What is XYWAV",
        },
        {
          url: "/narcolepsy/lower-sodium-oxybate-treatment-option/",
          text: "XYWAV and Sodium",
        },
        {
          url: "/narcolepsy/transitioning-from-xyrem/",
          text: "Transitioning From XYREM",
        },
        {
          url: "/narcolepsy/faq/",
          text: "FAQ",
        },
      ],
    },
    {
      text: "Getting XYWAV",
      subLinks: [
        {
          url: "/narcolepsy/talk-to-your-doctor/",
          text: "Talking to Your Doctor",
        },
        {
          url: "/narcolepsy/getting-xywav/",
          text: "Getting XYWAV",
        },
        {
          url: "/narcolepsy/dosing/",
          text: "Dosing",
        },
        {
          url: "/narcolepsy/xywav-side-effects/",
          text: "Side Effects",
        },
      ],
    },
    {
      text: "Support & Resources",
      subLinks: [
        {
          url: "/narcolepsy/jazzcares-for-xywav/",
          text: (
            <>
              JazzCares<sup>&reg;</sup> Savings & Financial Support
            </>
          ),
        },
        {
          url: "/narcolepsy/patient-support-programs/",
          text: (
            <>
              JazzCares<sup>&reg;</sup> Patient Support Programs
            </>
          ),
        },
        {
          url: "/narcolepsy/patient-resources/",
          text: "Additional Patient Resources",
        },
        {
          url: "/narcolepsy/educational-webinars/",
          text: "Educational Webinars",
        },
      ],
    },

    {
      url: "/patient-stories/",
      text: "Patient Stories",
    },
    {
      url: "/narcolepsy/stay-connected/",
      text: "Stay Connected",
    },
  ]
  return (
    <Layout location={location} headerType="narcolepsy" footerType="narcolepsy">
      <Seo
        title="User Site Map | XYWAV®"
        description="Explore the XYWAV.com site map. See XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero gradient="agnostic" alt="">
        <span tw="block pt-6 xl:pt-10">Site map</span>
      </StaticHero>

      <section css={sectionMd}>
        <Container>
          <div tw="pt-5 xl:pt-10">
            <ul tw="text-xl xl:text-[21px]">
              {sitemapLinks.map((link, i) => {
                return link.subLinks ? (
                  <li key={i} tw="font-black my-3 xl:(my-5)">
                    {link.text}
                    <ul tw="font-normal">
                      {link.subLinks.map((sublink, i) => {
                        return (
                          <li key={i} tw="py-2 ml-6">
                            <Anchor colour="merlin" link={sublink.url}>
                              {sublink.text}
                            </Anchor>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                ) : (
                  <li key={i} tw="font-black mb-4 xl:(mb-5)">
                    <Anchor colour="merlin" link={link.url}>
                      {link.text}
                    </Anchor>
                  </li>
                )
              })}
            </ul>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default SiteMap
